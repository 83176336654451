.games {
    color: #ccccc3;
    margin: auto;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 50%;
    overflow-y: scroll;
}

.date-picker {
    font-size: 20px;
    padding: 10px 0px 20px 0px;
    overflow: hidden;
}

.date-picker-part {
    float: left;
    width: 33.33%;
}

#date-picker-left, #date-picker-right {
    cursor: pointer;
    height: 10px;
}

.live {
    animation: color-change 5s infinite;
}

@keyframes color-change {
    0% { color: #910909; }
    50% { color: #5a0707; }
    100% { color: #910909; }
}

.game {
    margin: auto;
    overflow: hidden;
}

.gamesection {
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    float: left;
    height: 30px;
    color: #ccccc3;
}

.hometeam.gameday, .awayteam.gameday {
    width: 30%;
    margin-top: 3px;
}

.gamescores.gameday {
    width: 20%;
}

.hometeam, .awayteam {
    width: 30%;
    margin-top: 3px;
}

.gamescores {
    width: 33%;
}

.matchtime {
    width: 5%;
}

.matchscore {
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 5px;
    padding-right: 5px;
    width: 15px;
    height: 15px;
    font-size: 20px;
}

.matchscore-divider {
    padding-left: 5px;
    padding-right: 5px;
}

.matchtime {
    padding-left: 25px;
    width: 10px;
}

.games-player-selections {
    padding: 3px 0px 3px 0px;
    background-color: rgba(145, 9, 9, 0.7);;
    color: #ccccc3;
}

.games-player-selections-complete {
    padding: 3px 0px 3px 0px;
    background-color: rgba(13, 99, 31, 0.7);
    color: #ccccc3;
}

.game-whitespace {
    height: 50px;
}