.gameselection-container {
    height: 70%;
    color: #ccccc3;
}

.gameselection-button {
    background-color: #6b6b6b;
    color: #ccccc3;
    font-weight: bold;
    height: 30px;
    border: 0;
    transition: 0.3s;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 33%;
}

.gameselection-selection {
    margin-left: -5px;
}

.gameselection-button.active {
    background-color: #287a4f;
}
