.error-container, .error-container-active {
    display: none;
    
    position: fixed;
    top: 0%;
    background-color:  rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
}

.error-box {
    background-color:  rgba(0, 0, 0, 0.8);
    position: fixed;
    height: 50%;
    width: 75%;
    vertical-align: middle;
    line-height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px 0px 30px 0px;
}

.error-container-active {
    display: block;
}

.error-button {
    background-color: #6b6b6b;
    color: #ccccc3;
    font-weight: bold;
    height: 30px;
    border: 0;
    width: 23%;
}