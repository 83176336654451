.gamedetail-container {
    height: 70%;
    color: #ccccc3;
}

.gamedetail-row {
    margin: auto;
    overflow-x: hidden;
}

.gamedetail-section { 
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    float: left;
}

.hometeam {
    font-weight: bold;
    width: 30%;
}

.title {
    margin-top: 8px;
    font-weight: bold;
    font-size: 12px;
    width: 30%;
}

.awayteam {
    font-weight: bold;
    width: 30%
}

.result-img {
    padding-left: 5px;
    padding-right: 5px;
}