.head-main {
    background-color: #454242;
    color: #ccccc3;
    overflow: hidden;
    width: 100%;
    margin: auto;
    margin-bottom: -10px;
}

.round, .jackpot {
    float: left;
    width: 33.3%;
}

.logo {
    position: relative;
    margin-top: 10px;
    float: left;
    width: 33.3%;
}

.round {
    font-weight: bold;
}

.banner-text {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: rotate(-10deg);
    color: #c5c526;
    font-size: 16px;
    font-weight: bold;
}