.matchselector-container {
    height: 50%;
    overflow-y: scroll;
    color: #ccccc3;
    width: 100%;
    margin: auto;
    font-weight: bold;
}

.matchselector-submit-match-section {
    margin: auto;
    overflow: hidden;
}

.gameselection {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    float: left;
    height: 30px;
}

.gameselector-selections {
    padding: 3px 0px 3px 0px;
    background-color: rgba(145, 9, 9, 0.7);;
    color: #ccccc3;
    margin-top: -10px;
    margin-bottom: 10px;
}

.gameselector-selections-complete {
    padding: 3px 0px 3px 0px;
    background-color: rgba(13, 99, 31, 0.7);
    color: #ccccc3;
    margin-top: -10px;
    margin-bottom: 10px;
}

.matchselector-timebreak h4 {
    margin-top: -2px;
    padding-top: 3px;
}

.matchselector-leaguebreak h5 {
    margin-top: -3px;
}

.selected {
    color: #287a4f;
}

.postponed {
    color: rgba(183, 41, 41, 0.7);
}

.hometeam, .awayteam {
    width: 30%;
    margin-top: 3px;
}

.gameexpander {
    width: 33%;
}

.gameexpander img {
    padding-left: 10px;
    padding-right: 10px;
}