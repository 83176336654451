.gameprediction-container {
    height: 70%;
    color: #ccccc3;
}

.gameprediction-selections {
    overflow-x: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
}

.gameprediction-button {
    background-color: #6b6b6b;
    color: #ccccc3;
    font-weight: bold;
    height: 30px;
    border: 0;
    transition: 0.3s;
    border-radius: 10px;
}

.gameprediction-selection {
    margin-left: -5px;
}

.gameprediction-button-selection {
    background-color: #6b6b6b;
    margin-left: 20px;
    margin-right: 20px;
    border: 0;
    transition: 0.3s;
    width: 20%;
}

.gameprediction-button.active {
    background-color: #287a4f;
}

.gameprediction-toggle {
    display: inline-block;
    vertical-align: top;
}

.gameprediction-toggle span {
    float: left;
    width: 33.33%;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.gameprediction-toggle span.active {
    background-color: #287a4f;
}

.gameprediction-userlogin-button {
    background-color: #454242;
    color: #ccccc3;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    width: 100px;
    height: 25px;
}
