.predictions {
    /* margin: auto; */
    overflow-x: hidden;
}

.prediction {
    margin: auto;
    overflow: hidden;
}

.prediction-section {
    float: left;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.prediction-hometeam, .prediction-awayteam {
    width: 40%;
}

.versus {
    width: 5%;
}

.pick {
    font-weight: bold;
}