.qatarhero-container {
    margin: auto;
    padding-top: 25px;
    height: 80%;
    font-weight: bold;
}

.qatarhero {
    height: 50%;
    overflow-y: scroll;
}

.qatarhero-view-picker {
    color: #ccccc3;
    font-size: 20px;
    padding: 10px 0px 20px 0px;
    overflow: hidden;
}

.qatarhero-view-picker-part {
    float: left;
    width: 33.33%;
}

#qatarhero-view-picker-left, #qatar-hero-view-picker-right {
    cursor: pointer;
    height: 10px;
}

.qatarhero-table table {
    width: 100%;
}

.qatarhero-table table th {
    margin: auto;
}

.qatarhero-table table .table-column-header {
    border-left: 1px solid black;
    border-right: 1px solid black;
    padding-bottom: 7.5px;
}

.qatarhero-table table .table-column-header-contents {
    padding: 0px 5px 0px 5px;
}

.qatarhero-table table .table-column-header-contents:hover, .table-column-header-sort {
    cursor: pointer;
}

.qatarhero-table table .table-row-even {
    background-color: #918989;
}

.qatarhero-table table .table-row-odd {
    background-color: #746d6d;
}

.game {
    margin: auto;
    overflow: hidden;
}

.gamesection {
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    float: left;
    height: 30px;
    color: #ccccc3;
}

.hometeam.gameday, .awayteam.gameday {
    width: 30%;
    margin-top: 3px;
}

.gamescores.gameday {
    width: 20%;
}

.hometeam, .awayteam {
    width: 23%;
    margin-top: 3px;
}

.gamescores {
    width: 33%;
}

.matchtime {
    width: 10%;
}

.correct {
    margin-top: 5px;
    width: 5px;
}

.matchscore {
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 5px;
    padding-right: 5px;
    width: 15px;
    height: 15px;
    font-size: 20px;
}

.matchscore-divider {
    padding-left: 5px;
    padding-right: 5px;
}

.matchtime {
    padding-left: 25px;
    width: 10px;
}

.prediction {
    text-decoration: underline;
}

.games-player-selections {
    padding: 3px 0px 3px 0px;
    background-color: rgba(145, 9, 9, 0.7);;
    color: #ccccc3;
}

.games-player-selections-complete {
    padding: 3px 0px 3px 0px;
    background-color: rgba(13, 99, 31, 0.7);
    color: #ccccc3;
}

.game-whitespace {
    height: 50px;
}