.supersix {
    width: 100%;
    height: 1000px;
}

.hidden {
    display: none;
}

.supersix-container {
    width: 100%;
    position: fixed;
    background-color: #454242;
}

.supersix-menu {
    background-color: #babab6;
    overflow: hidden;
    height: 50px;
}

.supersix-menu-button {
    background-color: inherit;
    float: left;
    border-top: none;
    border-right: 2px solid black;
    font-size: 25px;
    padding-top: 5px;
    transition: 0.3s;
    cursor: pointer;
    outline: none;
    width: 25%;
    height: 50px;
}

.supersix-menu-button:hover {
    background-color: #878782;
}

.supersix-menu-button.active {
    background-color: #878782;
}