.scorescontainer {
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 70%;
    overflow-y: scroll;
}

.date-picker {
    color: #ccccc3;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0px 20px 0px;
    overflow: hidden;
}

.date-picker-part {
    float: left;
    width: 33.33%;
}

#date-picker-left, #date-picker-right {
    cursor: pointer;
    height: 10px;
}

.live {
    animation: color-change 5s infinite;
}

@keyframes color-change {
    0% { color: #910909; }
    50% { color: #5a0707; }
    100% { color: #910909; }
}

.scores {
    height: 50%;
    overflow-y: scroll;
}

.score {
    margin: auto;
    overflow-x: hidden;
}

.scoresection {
    padding-top: 10px;
    padding-bottom: 10px;
    float: left;
}

.playername {
    font-weight: bold;
    padding-top: 25px;
    width: 50%
}

.playerscore {
    padding-left: 40px;
    padding-top: 25px;
    width: 25%;
    font-weight: bold;
}

.even {
    background-color: #918989;
}

.odd {
    background-color: #746d6d;
}

.expand {
    margin-top: 15px;
}